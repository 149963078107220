import { navigate } from "package:/components/elements/Link";
import { DetailType, ROUTE_VIEW } from "package:/utils";
import type { AreaGeoResource, AreaShortResource } from "@greentrails/api";
import {
  type LngLat,
  LngLatBounds,
  type LngLatLike,
  type MaptilerMap,
} from "@maptiler/sdk";
import type { FeatureCollection } from "geojson";

class MapAreas {
  private map: MaptilerMap;
  private mapCanvas: HTMLElement;
  private areas: AreaShortResource[];
  private areasGeoData: AreaGeoResource[];
  private lastHoveredId: string | undefined;
  private maxzoom = 12;

  constructor(map: MaptilerMap, mapCanvas: HTMLElement, areas: AreaShortResource[]) {
    this.map = map;
    this.mapCanvas = mapCanvas;
    this.areas = areas;
    this.areasGeoData = [];

    this.initAreas();
  }

  private initAreas() {
    const areaCircleGeoJson: FeatureCollection = {
      type: "FeatureCollection",
      features: [],
    };

    for (const area of this.areas) {
      const center = area.bounds
        ? [
            (area.bounds[0][0] + area.bounds[1][0]) / 2,
            (area.bounds[0][1] + area.bounds[1][1]) / 2,
          ]
        : [];

      areaCircleGeoJson.features.push({
        type: "Feature",
        id: this.areas.indexOf(area),
        properties: {
          slug: area.slug,
          COUNT: area.rounds.length,
          // area.trails.filter((trail) => trail.type === "trail").length +
          // area.rounds.length,
        },
        geometry: {
          type: "Point",
          coordinates: center,
        },
      });
    }

    this.map.addSource("areas", {
      type: "geojson",
      data: areaCircleGeoJson,
      cluster: false,
    });

    this.map.addLayer({
      id: "area_fill",
      type: "circle",
      source: "areas",
      maxzoom: this.maxzoom,
      layout: {},
      paint: {
        "circle-opacity": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          0.8,
          1,
        ],
        "circle-radius": 26,
        "circle-color": "#003723",
        "circle-stroke-width": 0.2,
        "circle-stroke-color": "#fff",
      },
    });

    this.map.addLayer({
      id: "area-count-layer",
      type: "symbol",
      source: "areas",
      maxzoom: this.maxzoom,
      layout: {
        "text-allow-overlap": true,
        "text-ignore-placement": true,
        "text-field": ["get", "COUNT"],
        "text-font": ["Roboto Regular"],
        "text-anchor": "center",
        "text-offset": [-0.08, 0],
        "text-size": 18,
      },
      paint: {
        "text-color": "#FFFFFF",
      },
    });

    this.map.on("mouseenter", "area_fill", this.onMouseEnter.bind(this));
    this.map.on("mouseleave", "area_fill", this.onMouseLeave.bind(this));
    this.map.on("click", "area_fill", this.onClick.bind(this));
  }

  private onMouseEnter(event: any): void {
    // const features = this.map.queryRenderedFeatures(event?.point);
    if (event?.features && event.features.length > 0) {
      this.lastHoveredId = event.features[0]?.id;
      this.map.setFeatureState(
        {
          source: "areas",
          id: this.lastHoveredId,
        },
        {
          hover: true,
        },
      );
      // this.map.setPaintProperty("area", "circle-color", "#3bb2d0");
      this.mapCanvas.style.cursor = "pointer";
    }
  }

  private onMouseLeave(): void {
    if (this.lastHoveredId !== undefined) {
      this.map.setFeatureState(
        {
          source: "areas",
          id: this.lastHoveredId,
        },
        {
          hover: false,
        },
      );
      this.lastHoveredId = undefined;
    }
    // this.map.setPaintProperty("area", "circle-color", "#3887be");
    this.mapCanvas.style.cursor = "";
  }

  private onClick(event: any): void {
    if (event?.features && event.features.length > 0) {
      const id = event.features[0]?.properties?.id;
      // updateMapPosition(true);
      event.preventDefault();
      navigate({
        name: "lang-map-area",
        params: {
          area: event.features[0]?.properties?.slug,
        },
        query: {
          view: ROUTE_VIEW.PREVIEW,
        },
        replace: true,
      });
    }
  }

  public getBounds(
    areaSlug,
    detailSlug?: string,
    detailType?: DetailType,
  ): LngLatBounds | null {
    const area: AreaShortResource | undefined = this.areas.find(
      (area) => area.slug === areaSlug,
    );
    const areaGeoData: AreaGeoResource | undefined = this.areasGeoData?.find(
      (area) => area.slug === areaSlug,
    );

    if (area === undefined) return null;

    switch (detailType) {
      case DetailType.Round: {
        if (areaGeoData?.rounds) {
          const round = areaGeoData.rounds.find((round) => round.slug === detailSlug);
          return round?.bounds
            ? new LngLatBounds(round?.bounds as LngLatLike)
            : new LngLatBounds(area.bounds as LngLatLike);
        }
        return new LngLatBounds(area.bounds as LngLatLike);
      }
      case DetailType.Trail:
        if (areaGeoData?.trails) {
          const trail = areaGeoData.trails.find((trail) => trail.slug === detailSlug);
          return trail?.bounds
            ? new LngLatBounds(trail?.bounds as LngLatLike)
            : new LngLatBounds(area.bounds as LngLatLike);
        }
        return new LngLatBounds(area.bounds as LngLatLike);
      case DetailType.Poi: {
        if (area.pois) {
          const poi = area.pois.find((poi) => poi.slug === detailSlug);
          if (poi?.lng && poi.lat) {
            return new LngLatBounds([poi.lng, poi.lat, poi.lng, poi.lat]);
          }
        }
        return new LngLatBounds(area.bounds as LngLatLike);
      }
      default: {
        return new LngLatBounds(area.bounds as LngLatLike);
      }
    }
  }

  public getAreasInBounds(bounds: LngLatBounds, center: LngLat | null = null): string[] {
    const areaSlugsInBounds: string[] = [];
    this.areas.map((area) => {
      const areaBounds = new LngLatBounds(area.bounds as LngLatLike);
      if (
        areaBounds &&
        bounds.contains(areaBounds.getSouthWest().toArray()) &&
        bounds.contains(areaBounds.getNorthEast().toArray())
      ) {
        areaSlugsInBounds.push(area.slug);
        return;
      }

      if (center && areaBounds && areaBounds.contains([center.lng, center.lat])) {
        areaSlugsInBounds.push(area.slug);
        return;
      }

      area.pois?.map((poi) => {
        if (poi.lng && poi.lat && bounds.contains([poi.lng, poi.lat])) {
          areaSlugsInBounds.push(area.slug);
          return;
        }
      });
    });

    return areaSlugsInBounds;
  }

  public updateAreas(areasGeoData: AreaGeoResource[]) {
    this.areasGeoData = areasGeoData;
  }

  // setter for maxzoom
  public setMaxZoom(maxzoom: number) {
    if (this.maxzoom === maxzoom) return;
    
    this.maxzoom = maxzoom;
    for (const layer of ["area-count-layer", "area_fill"]) {
      const currentLayer = this.map.getLayer(layer);
      if (currentLayer) {
        const currentMinzoom = currentLayer.minzoom;
        this.map.setLayerZoomRange(layer, currentMinzoom, maxzoom);
      }
    }
  }

  public destroy() {
    this.map.off("mouseenter", "area_fill", this.onMouseEnter.bind(this));
    this.map.off("mouseleave", "area_fill", this.onMouseLeave.bind(this));
    this.map.off("click", "area_fill", this.onClick.bind(this));
  }
}

export default MapAreas;
