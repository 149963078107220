import { Icon, type IconName } from "package:/components/elements/Icon";
import type { PoiCategoryResource } from "@greentrails/api";
import { defineComponent, ref, useTranslations } from "#imports";
import { Button } from "../elements/Button";
import { Checkbox } from "../elements/Checkbox";
import { MobileSafeArea } from "../elements/MobileSafeArea";

export const PoiFilter = defineComponent(
  (props: {
    categories: PoiCategoryResource[] | null;
    values: string[];
    onChange: (filter: string[]) => void;
  }) => {
    const t = useTranslations();
    const form = ref<HTMLFormElement>();

    const onChange = () => {
      props.onChange([...new FormData(form.value).keys()]);
    };

    const clear = () => {
      props.onChange([]);
    };

    return () => (
      <MobileSafeArea top={false}>
        <h2 class="pb-7 font-bold text-xl">{t("map.filter.poi.headline")}</h2>

        <form ref={form}>
          {props.categories?.map((cat) => {
            return (
              <div class="pb-7" key={`poi_filter_${cat.slug}`}>
                <Checkbox
                  class={"flex flex-row-reverse justify-between"}
                  id={cat.slug}
                  checked={props.values.includes(cat.slug) || false}
                  onChange={onChange}
                >
                  <div class="flex items-center text-xl">
                    <Icon
                      class="pr-4 text-[0.725em]"
                      name={`poi-${cat.icon}` as IconName}
                    />
                    <span class="font-light">{cat.title}</span>
                  </div>
                </Checkbox>
              </div>
            );
          })}
        </form>

        <div class="pt-3">
          <Button variant="white" onClick={() => clear()}>
            {t("map.filter.unselectAll")}
          </Button>
        </div>
      </MobileSafeArea>
    );
  },
  {
    props: ["onChange", "values", "categories"],
  },
);
