import { MobileSafeArea } from "package:/components/elements/MobileSafeArea.jsx";
import { MainMapBreadcrump } from "package:/components/modules/MainMapBreadcrump";
import { toast } from "package:/components/elements/Toasts";
import { useEnv } from "package:/composables/useEnv.js";
import { DetailType, ROUTE_VIEW } from "package:/utils";
import { log } from "package:/utils";
import type { AreaGeoResource, AreaShortResource } from "@greentrails/api";
import { LngLatBounds, LngLat, type Map as MaptilerMap, config } from "@maptiler/sdk";
import { Actions } from "actions";
import {
  defineComponent,
  effect,
  markRaw,
  onErrorCaptured,
  onMounted,
  onUnmounted,
  ref,
  shallowRef,
  useIsDesktop,
  getDeviceType,
  useNuxtApp,
  useRouter,
  useGeoLocation,
  useTranslations,
} from "#imports";
import style from "./MainMap.module.css";
import MapAreas from "./MapAreas";
import MapGeoLayer from "./MapGeoLayer";
import { loadMapIcons } from "./MapUtils.js";
import { Geolocation } from "@capacitor/geolocation";

export enum MapAction {
  ZoomOut = "map.zoom.out",
  ZoomIn = "map.zoom.in",
  GeolcationTrigger = "map.geolocation.trigger",
}

export const MainMap = defineComponent(
  (props: {
    areas: {
      bounds: [[number, number], [number, number]];
      data: AreaShortResource[];
    };
    filter: string[];
    loadGeoData: (area: string, onload: (data: AreaGeoResource) => void) => void;
    onLoad?: () => void;
  }) => {
    const env = useEnv();
    const isDesktop = useIsDesktop();
    const deviceType = getDeviceType();
    const router = useRouter();
    const t = useTranslations();
    const geoLocation = useGeoLocation();
    const { $map } = useNuxtApp();
    const mapContainer = shallowRef<HTMLElement>();
    const map = shallowRef<MaptilerMap | null>();
    const mobileSafeArea = ref(null);
    const mapMoved = ref(false);
    const areasGeoData: AreaGeoResource[] = [];
    const areas = props.areas;
    let greentrailsBoundingBox = new LngLatBounds([
      [8.5773630833333, 51.24668137],
      [8.8345878376612, 51.291240192072],
    ]);
    try {
      greentrailsBoundingBox = new LngLatBounds(areas?.bounds);
    } catch (err) {
      log.error("err setting bounds", err);
    }

    let fitBoundsCompleted = true;
    let moveMapToUserLocation = false;

    let mapAreas: MapAreas;
    const mapGeoLayer = shallowRef<MapGeoLayer>();

    effect(() => {
      mapGeoLayer.value?.setFilter(props.filter);
    });

    effect(() => {
      if (mapMoved.value) {
        moveMapToUserLocation = false;
      }
    });

    effect(() => {
      if (mapGeoLayer.value) {
        mapGeoLayer.value.updateUserLocationMarker(geoLocation.lastUserPosition.value);
        if (moveMapToUserLocation) {
          moveMapToUserLocation = false;
          if (geoLocation.lastUserPosition.value) {
            updateMapPosition(
              true,
              true,
              new LngLat(
                geoLocation.lastUserPosition.value.coords.longitude,
                geoLocation.lastUserPosition.value.coords.latitude,
              ),
            );
          }
        }
      }
    });

    onMounted(async () => {
      config.apiKey = env.MAPTILER_TOKEN;

      const mapInstance = await $map.init(
        {
          container: mapContainer.value,
          bounds: greentrailsBoundingBox,
          navigationControl: false,
          geolocateControl: false,
        },
        router.currentRoute.value?.params.lang,
      );

      // @ts-ignore
      map.value = markRaw(mapInstance);

      // fitBoundsCompleted is true after the initial fitBounds because no change of map isn't necessary;
      fitBoundsCompleted = true;

      requestAnimationFrame(() => {
        if (map.value) {
          const mapCanvas = map.value?.getCanvasContainer();
          loadMapIcons(map.value);

          if (mapCanvas) {
            mapAreas = new MapAreas(map.value, mapCanvas, areas.data);
            mapGeoLayer.value = new MapGeoLayer(map.value, mapCanvas, [], {});
            mapGeoLayer.value.updateUserLocationMarker(
              geoLocation.lastUserPosition.value,
            );
          }

          // set initial map position
          updateMapPosition();

          props.onLoad?.();
        }
      });

      map.value?.on("drag", () => {
        if (fitBoundsCompleted) {
          mapMoved.value = true;
          // set min zoom for geo layer and areas to default values / behavior
          const currentView = router.currentRoute.value?.query.view as string;
          if (!currentView || currentView === null) {
            mapGeoLayer.value?.setMinZoom(12);
            mapAreas?.setMaxZoom(12);
          }
        }
        window.dispatchEvent(new CustomEvent("map.move"));
      });

      map.value?.on("zoom", () => {
        if (fitBoundsCompleted) {
          mapMoved.value = true;
          // set min zoom for geo layer and areas to default values / behavior
          const currentView = router.currentRoute.value?.query.view as string;
          if (!currentView || currentView === null) {
            mapGeoLayer.value?.setMinZoom(12);
            mapAreas?.setMaxZoom(12);
          }
        }
        // cant do this because zoom is emitted on load for some reason
        // window.dispatchEvent(new CustomEvent("map.move"));
      });

      map.value?.on("moveend", () => {
        fitBoundsCompleted = true;
        getAreasInBounds()?.map((area) => {
          props.loadGeoData(area, updateGeoData);
        });
      });
    });

    onUnmounted(() => {
      afterEachUnregister();
      if (mapGeoLayer.value) {
        mapGeoLayer.value.destroy();
      }
      if (mapAreas) {
        mapAreas.destroy();
      }
      map.value?.remove();
      map.value = null;
    });

    const afterEachUnregister = router.afterEach((to, from) => {
      if (!to.params.area) return;
      if (to.query.view === null) {
        // disable active route without updating viewport
        updateMapPosition(false, false);
        return;
      }

      // do not update map position if the view is changed from preview to map which happens when preview is closed
      if (isDesktop.value && !(from.query.view === ROUTE_VIEW.DETAIL && !to.query.view)) {
        updateMapPosition(true);
      }

      if (
        !isDesktop.value &&
        (from.query.view !== ROUTE_VIEW.PREVIEW || to.query.view === ROUTE_VIEW.PREVIEW)
      ) {
        updateMapPosition(true);
      }
    });

    const updateGeoData = (data) => {
      areasGeoData.push(data);
      if (mapGeoLayer.value) {
        mapAreas.updateAreas(areasGeoData);
        mapGeoLayer.value.updateGeoData(areasGeoData);
        const currentType = router.currentRoute.value?.params.type as DetailType;
        const currentDetail = router.currentRoute.value?.params.detail as string;
        const currentView = router.currentRoute.value?.query.view as string;
        if (currentView === null) {
          mapGeoLayer.value.resetActiveRoute();
          // mapMoved.value = true;
        } else {
          mapGeoLayer.value.setActiveRoute(currentDetail, currentType);
        }
      }
    };

    const updateMapPosition = (
      animate = false,
      updateBounds = true,
      userGeoLocation: LngLat | null = null,
    ) => {
      if (map.value === undefined) return;
      const currentArea = router.currentRoute.value?.params.area as string;
      const currentType = router.currentRoute.value?.params.type as DetailType;
      const currentDetail = router.currentRoute.value?.params.detail as string;
      const currentView = router.currentRoute.value?.query.view as string;

      // set min zoom for geo layer and areas to ensure that trails and rounds always visible independent of map zoom level
      // if preview is closed set min zoom to default value
      if (currentArea) {
        mapGeoLayer?.value?.setMinZoom(1);
        mapAreas?.setMaxZoom(1);
      } else {
        mapGeoLayer?.value?.setMinZoom(12);
        mapAreas?.setMaxZoom(12);
      }

      if (mapGeoLayer.value) {
        if (currentView === null) {
          mapGeoLayer.value.resetActiveRoute();
          mapMoved.value = true;
        } else {
          mapGeoLayer.value.setActiveRoute(currentDetail, currentType);
        }
      }
      if (updateBounds) {
        mapMoved.value = false;

        if (userGeoLocation) {
          mapMoved.value = true;
        }

        let bounds = mapAreas
          ? mapAreas.getBounds(currentArea, currentDetail, currentType)
          : undefined;

        if (bounds && (currentArea || currentDetail)) {
          fitBoundsCompleted = false;
          if (bounds !== null) {
            bounds = new LngLatBounds(
              bounds.getSouthWest().toArray(),
              bounds.getNorthEast().toArray(),
            );

            if (userGeoLocation && !greentrailsBoundingBox.contains(userGeoLocation)) {
              bounds.extend(userGeoLocation);
            } else if (userGeoLocation) {
              bounds = new LngLatBounds(
                userGeoLocation.toArray(),
                userGeoLocation.toArray(),
              );
            }
            // calculate safe area offset for mobile
            const mobileSafeAreaEl = mobileSafeArea.value?.$el as HTMLElement;
            let safeAreaTop = 0;
            if (mobileSafeAreaEl?.children[0]) {
              safeAreaTop =
                mobileSafeAreaEl.clientHeight - mobileSafeAreaEl.children[0].clientHeight;
            }

            map.value?.fitBounds(bounds, {
              padding: {
                top: deviceType === "desktop" ? 120 : 80 + safeAreaTop,
                right: deviceType === "desktop" ? 465 : 20,
                bottom: deviceType === "desktop" ? 60 : 330,
                left: deviceType === "desktop" ? 60 : 20,
              },
              animate: animate,
              maxZoom: currentType === DetailType.Poi ? 16 : 20,
            });
          }
        } else {
          // fit to greentrails bounding box
          fitBoundsCompleted = false;
          let bounds = new LngLatBounds(
            greentrailsBoundingBox.getSouthWest().toArray(),
            greentrailsBoundingBox.getNorthEast().toArray(),
          );

          if (userGeoLocation && !greentrailsBoundingBox.contains(userGeoLocation)) {
            bounds.extend(userGeoLocation);
          } else if (userGeoLocation) {
            bounds = new LngLatBounds(
              userGeoLocation.toArray(),
              userGeoLocation.toArray(),
            );
          }

          map.value?.fitBounds(bounds, {
            padding: {
              top: isDesktop.value ? 140 : 100,
              left: isDesktop.value ? 140 : 60,
              right: isDesktop.value ? 140 : 60,
              bottom: isDesktop.value ? 140 : 100,
            },
            animate: animate,
          });
        }
      }
    };

    const getAreasInBounds = () => {
      if (!map.value || !mapAreas) return;
      const areasInBounds = mapAreas.getAreasInBounds(
        map.value?.getBounds(),
        map.value?.getCenter(),
      );
      return areasInBounds;
    };

    onErrorCaptured((err) => {
      log.error("err", err);
      return false;
    });

    Actions.register(MapAction.ZoomOut, {
      run: async () => {
        map.value?.zoomOut();
      },
    });

    Actions.register(MapAction.ZoomIn, {
      run: async () => {
        map.value?.zoomIn();
      },
    });

    Actions.register(MapAction.GeolcationTrigger, {
      run: async () => {
        const permission = await Geolocation.checkPermissions();
        if (permission.location !== "denied" && geoLocation.lastUserPosition.value) {
          updateMapPosition(
            true,
            true,
            new LngLat(
              geoLocation.lastUserPosition.value.coords.longitude,
              geoLocation.lastUserPosition.value.coords.latitude,
            ),
          );
        } else if (permission.location === "granted") {
          geoLocation.requestGeolocation();
          moveMapToUserLocation = true;
        } else {
          geoLocation.requestGeolocation();
          toast({
            id: "geolocation-denied",
            message: t("error.location.denied"),
            time: 500000,
            variant: "error",
          });
        }
      },
    });

    return () => (
      <div class="h-full w-full">
        <div class={[style.map, "h-full w-full"]}>
          <div class="h-full w-full" ref={mapContainer} />
        </div>

        <MobileSafeArea class="fixed top-5 left-5 lg:top-8" ref={mobileSafeArea}>
          <MainMapBreadcrump
            areas={areas.data}
            mapMoved={mapMoved.value}
            onUpdateMapPosition={() => {
              const routerUnregister = router.afterEach(() => {
                updateMapPosition(true);
                fitBoundsCompleted = false;
                routerUnregister();
              });
            }}
          />
        </MobileSafeArea>
      </div>
    );
  },
  {
    name: "MainMap",
    props: ["areas", "filter", "loadGeoData", "onLoad"],
  },
);
