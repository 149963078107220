import { Button, buttonVariants } from "package:/components/elements/Button";
import { Dynamic } from "package:/components/elements/Dynamic";
import { Icon } from "package:/components/elements/Icon";
import { Link, navigate } from "package:/components/elements/Link";
import { MobileSafeArea } from "package:/components/elements/MobileSafeArea.jsx";
import { Loader } from "package:/components/elements/loader";
import { MainMap, MapAction } from "package:/components/modules/Map/MainMap";
import { PageLayer } from "package:/components/modules/PageLayer";
import { PoiFilter } from "package:/components/modules/PoiFilter";
import { Style, setStatusBar } from "package:/composables/theme";
import { useApi, useApiClient } from "package:/composables/useApi.js";
import { log } from "package:/utils";
import { ROUTE_VIEW } from "package:/utils.js";
import type { AreaGeoResource } from "@greentrails/api";
import { Actions } from "actions";
import { ClientOnly } from "#components";
import {
  defineComponent,
  effect,
  onMounted,
  ref,
  useIsDesktop,
  useLanguage,
  useListener,
  useRouter,
  useTranslations,
} from "#imports";

// biome-ignore lint/style/noDefaultExport: <explanation>
export default defineComponent((_, { slots }) => {
  const lang = useLanguage();
  const t = useTranslations();
  const isDesktop = useIsDesktop();
  const router = useRouter();
  const filterOpen = ref(false);
  const mapLoaded = ref(false);
  const mounted = ref(false);

  onMounted(() => {
    mounted.value = true;
  });

  const categories = useApi(async (api) => {
    return (await api.poi.poisCategories()).data;
  }, "poi_categories");

  const queue: string[] = [];

  setStatusBar({ style: Style.Light });

  const poiFilterCategories = ref<string[]>([]);
  effect(() => {
    poiFilterCategories.value = categories.data.value?.map((cat) => cat.slug) || [];
  });

  // meh (dont close the drawer before navigation started)
  const view = ref<string>(router.currentRoute.value.query.view as string);
  const type = ref<string>(router.currentRoute.value?.params?.type as string);
  router.afterEach((to, from) => {
    if (to.name?.toString().startsWith("lang-map")) {
      view.value = router.currentRoute.value.query.view as string;
      type.value = router.currentRoute.value?.params?.type as string;
    }

    // close filter when detail view is open
    if (view.value) {
      filterOpen.value = false;
    }
  });

  const { data: areasData } = useApi((api) => api.area.areasIndex());

  const apiClient = useApiClient();

  const loadAreaGeoData = async (
    area: string,
    onload: (data: AreaGeoResource) => void,
  ) => {
    if (area && queue.indexOf(area) === -1) {
      queue.push(area);

      const { data: geodata } = await apiClient.area.areasGeoData(area).catch((e) => {
        //TODO handle error remove area from queue to enable retry
        log.error("error loading geodata", e);
        return { data: undefined };
      });

      if (geodata) {
        onload(geodata);
      }
    }
  };

  useListener(window, "map.blur", () => {
    if (view.value !== "detail") {
      navigate({
        query: {
          view: null,
        },
      });
    }
  });

  return () => {
    return (
      <div class="layout-map">
        {!mapLoaded.value ||
          (!mounted.value && (
            <div class="-translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 z-20 h-[100px] w-[100px]">
              <Loader />
            </div>
          ))}

        <div class="fixed top-0 left-0 z-10 h-full w-full">
          <ClientOnly>
            {areasData.value?.data && (
              <MainMap
                // @ts-ignore codegen/docs are wrong here
                areas={areasData.value}
                filter={poiFilterCategories.value}
                loadGeoData={loadAreaGeoData}
                onLoad={() => {
                  log.info("map loaded");
                  mapLoaded.value = true;
                }}
              />
            )}
          </ClientOnly>

          <MobileSafeArea
            top={false}
            class="absolute right-5 bottom-10 flex flex-col gap-3 lg:bottom-12"
          >
            <Button
              variant="iconLight"
              onClick={() => {
                filterOpen.value = true;
                navigate({
                  query: {
                    view: null,
                  },
                });
              }}
              label={t("map.ariaLabelFilter")}
            >
              <Icon name="filter" aria-hidden="true" />
            </Button>
            <Button
              variant="iconLight"
              onClick={() => Actions.run(MapAction.GeolcationTrigger)}
              label={t("map.ariaLabelPosition")}
            >
              <Icon name="position" aria-hidden="true" />
            </Button>
            <Button
              class="hidden md:inline-flex"
              variant="iconLight"
              onClick={() => Actions.run(MapAction.ZoomIn)}
              label={t("map.ariaLabelZoomIn")}
            >
              <Icon name="plus" aria-hidden="true" />
            </Button>
            <Button
              class="hidden md:inline-flex"
              variant="iconLight"
              onClick={() => Actions.run(MapAction.ZoomOut)}
              label={t("map.ariaLabelZoomOut")}
            >
              <Icon name="minus" aria-hidden="true" />
            </Button>
          </MobileSafeArea>
        </div>

        <div class="fixed top-5 right-5 z-10 lg:top-8">
          <MobileSafeArea>
            <Button
              class={buttonVariants.iconLight}
              onClick={() => {
                navigate({
                  name: "lang",
                  params: {
                    lang: lang.value,
                  },
                });
              }}
              label={t("map.ariaLabelCloseMap")}
            >
              <Icon name="close" aria-hidden />
            </Button>
          </MobileSafeArea>
        </div>

        <div
          style={{
            "--color-bg": "var(--color-gray)",
            color: "var(--color-green-800)",
          }}
        >
          {filterOpen.value && (
            <PageLayer
              showClose={true}
              drawerDisabled={true}
              drawerDynamicHeight={true}
              variant="light"
              onClose={() => {
                filterOpen.value = false;
              }}
              class="pointer-events-none absolute top-0 left-0 z-50 h-full w-full"
            >
              <div class="h-full px-5 lg:pt-28">
                <Dynamic data={categories} class="h-full">
                  <PoiFilter
                    values={poiFilterCategories.value}
                    categories={categories.data.value}
                    onChange={(filter) => {
                      poiFilterCategories.value = filter;
                    }}
                  />
                </Dynamic>
              </div>
            </PageLayer>
          )}
        </div>

        <div
          style={
            type.value
              ? {
                  "--color-bg": "var(--color-gray)",
                  color: "var(--color-green-800)",
                }
              : {
                  "--color-bg": "var(--color-green-800)",
                  color: "var(--color-text)",
                }
          }
        >
          {view.value && (
            <PageLayer
              showTitle={true}
              showClose={true}
              variant={type.value && "light"}
              class={["pointer-events-none absolute top-0 left-0 z-50 h-full w-full"]}
              onOpen={() => {
                navigate({
                  query: {
                    view: ROUTE_VIEW.DETAIL,
                  },
                });
              }}
              onClose={() => {
                navigate({
                  query: {
                    view: null,
                  },
                });
              }}
              onCollapse={() => {
                navigate({
                  query: {
                    view: isDesktop.value ? null : ROUTE_VIEW.PREVIEW,
                  },
                });
              }}
            >
              {slots.default?.()}
            </PageLayer>
          )}
        </div>
      </div>
    );
  };
});
