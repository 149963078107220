import { buttonVariants } from "package:/components/elements/Button.jsx";
import { Icon } from "package:/components/elements/Icon.jsx";
import { Link } from "package:/components/elements/Link";
import { ROUTE_VIEW } from "package:/utils";
import { DetailType } from "package:/utils.js";
import type {
  AreaShortResource,
  PoiShortResource,
  RoundShortResource,
  TrailShortResource,
} from "@greentrails/api";
import { twMerge } from "tailwind-merge";
import {
  defineComponent,
  onMounted,
  ref,
  useIsDesktop,
  useLanguage,
  useRouter,
  useTranslations,
  watch,
} from "#imports";
import type { Ref } from "#imports";

export const MainMapBreadcrump = defineComponent(
  (
    props: {
      areas: AreaShortResource[];
      mapMoved: boolean;
      onUpdateMapPosition: () => void;
    },
    context,
  ) => {
    const lang = useLanguage();
    const router = useRouter();
    const t = useTranslations();
    const label = ref(t("map.breadcrumb.root"));
    const path = ref(`/${lang.value}/map`);
    const prevPath: Ref<string | null> = ref("");

    const isDesktop = useIsDesktop();

    onMounted(() => {
      updateBreadcrump();
    });

    watch(router.currentRoute, () => {
      updateBreadcrump();
    });

    const updateBreadcrump = () => {
      const areaSlug = router.currentRoute.value?.params.area;
      const type = router.currentRoute.value?.params.type;
      const detailSlug = router.currentRoute.value?.params.detail;
      if (detailSlug) {
        const area = props.areas.find((area) => area.slug === areaSlug);
        let detail:
          | TrailShortResource
          | RoundShortResource
          | PoiShortResource
          | undefined;
        switch (type) {
          case DetailType.Round:
            detail = area?.rounds.find((round) => round.slug === detailSlug);
            break;
          case DetailType.Trail:
            detail = area?.trails.find((trail) => trail.slug === detailSlug);
            break;
          case DetailType.Poi:
            detail = area?.pois
              ? area?.pois.find((poi) => poi.slug === detailSlug)
              : null;
            break;
        }

        label.value = detail ? detail.title : "";
        path.value = `/${lang.value}/map/${areaSlug}/${type}/${detailSlug}?view=${
          isDesktop.value ? ROUTE_VIEW.DETAIL : ROUTE_VIEW.PREVIEW
        }`;
        prevPath.value = `/${lang.value}/map/${areaSlug}?view=${
          isDesktop.value ? ROUTE_VIEW.DETAIL : ROUTE_VIEW.PREVIEW
        }`;
      } else if (areaSlug) {
        const area = props.areas.find((area) => area.slug === areaSlug);
        label.value = area ? area.title : "";
        path.value = `/${lang.value}/map/${areaSlug}?view=${
          isDesktop.value ? ROUTE_VIEW.DETAIL : ROUTE_VIEW.PREVIEW
        }`;
        prevPath.value = `/${lang.value}/map`;
      } else {
        label.value = t("map.breadcrumb.root");
        path.value = `/${lang.value}/map`;
        prevPath.value = null;
      }
    };

    const handleButtonClick = () => {
      context.emit("update-map-position");
    };

    return () => (
      <div class="flex gap-2">
        {prevPath.value && (
          <Link
            class={buttonVariants.iconLight}
            href={prevPath.value}
            onClick={handleButtonClick}
          >
            <Icon name="arrow-left" />
          </Link>
        )}
        {label.value && (
          <Link
            class={twMerge([
              buttonVariants.big,
              "ellipsis max-w-[calc(100vw-160px)] overflow-hidden whitespace-nowrap",
              props.mapMoved ? buttonVariants.bigLight : "pointer-events-none",
            ])}
            href={path.value}
            onClick={handleButtonClick}
          >
            <span class="max-w-[250px] overflow-hidden text-ellipsis whitespace-nowrap">
              {label.value}
            </span>
          </Link>
        )}
      </div>
    );
  },
  {
    name: "MainMapBreadcrump",
    props: ["areas", "mapMoved"],
    emits: ["update-map-position"],
  },
);
